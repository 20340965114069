import * as React from 'react'
import { CartProvider } from 'src/contexts/cart-context'
import WithApolloContext from './WithApolloContext'

export const withCartContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithApolloContext>
        <WithCartContext>
          <Component {...props} />
        </WithCartContext>
      </WithApolloContext>
    )

export default function WithCartContext({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <WithApolloContext>
      <CartProvider>{children}</CartProvider>
    </WithApolloContext>
  )
}
